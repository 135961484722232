

.menuitems{
    padding-top: 10px;
}
@media only screen and (min-width: 971px) {
    .iron-dropdown .MuiPaper-root-10{
        top: 12% !important;
    
        left: 78% !important;
        position: absolute;
    }
  }


@media (min-width: 768px) and (max-width: 970px) {
    #simple-popper .MuiPaper-root-10{
        top: 19% !important;
    }
    .input-wrap{
        width: 100% !important;
    }
    .iron-dropdown .MuiPaper-root-10{
        top: 19% !important;
            left: 44% !important;
            width: 54% !important;
    }
}


@media (min-width: 360px) and (max-width: 767px){
/* 
    .input-wrap{
        width: 87% !important;
    } */
}


