.srch-icon .search-btn .material-icons {
    top: 18%;
}

.srch-icon .search-form {
    padding: 11px;
}

.mt-6 .hsptl-name {
    display: none;
}

.mt-6 {
    margin-top: 12px;
}

.second-header .menuitems {
    padding: 0px;
}

.second-header .price-psi a strong {
    font-size: 15px;
}

.second-header .price-psi a {
    font-size: 11px;
}

.text-right {
    text-align: right !important;
}

.logoImage {
    /* margin: auto; */
    width: 100px;
}

.header-last .iron-cart-wrap a {
    margin: 0px 5px !important;
}

.header-last .d-flex {
    margin-top: 12px;
}

.header-last .gray-hospital {
    margin: 16px 7px;
    text-align: center;
}

.jsty-end {
    width: 100%;
    /* padding-top: 4px; */
    justify-content: flex-end !important;
}

.jsty-end input {
    padding: 8px;
    position: relative;
    background: #f1f0f0;
    border: none;
    width: 90%;
    border-radius: 18px;
}

.jsty-end i.fa-search {
    position: absolute;
    top: 8px;
    right: 40px;
    color: black;
}

.other-search .search-btn {
    top: 32% !important;
    right: 4% !important;
}

.other-header .react-autosuggest__input {
    width: 95% !important;
}

.banner-section {
    padding: 23px 0px 6px 0px !important;
}

.react-autosuggest__suggestion .searched-product ul li a {
    color: #000 !important;
    /* font-weight: bold; */
    font-size: 16px;
}

.price-psi {
    align-items: center;
    height: 100%;
    display: flex;
}

.price-psi a {
    color: #ffffff !important;
    font-size: 15px;
    background: #ff5722 !important;
    text-align: center;
    padding: 10px 5px 5px 5px;
    border: 1px solid #ff5722;
    border-radius: 12px;
    width: 100%;
    transition: 0.3s;
}

.price-psi a:hover {
    background: #dd4312;
    border: 1px solid #dd4312;
    color: white;
}

.price-psi a strong {
    font-size: 22px;
    margin-bottom: 5px;
    display: inline-block;
}

.hidden-mobile {
    display: block;
    margin-left: 15px;
}

.hidden-desktop {
    display: none !important;
}

.hidden-smaller-desktop {
    display: none;
}

@media only screen and (max-width: 959px) {
    .hidden-smaller-desktop {
        display: block;
    }
}

.hidden-search-smaller-desktop {
    display: block;
}

@media only screen and (min-width: 959px) and (max-width: 1200px) {
    .hidden-search-smaller-desktop {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .header-last .gray-hospital {
        margin: 8px 7px;
        text-align: center;
    }

    .header-last {
        display: none;
    }

    .jsty-end {
        margin: -20px 15px;
        width: 82%;
    }

    .mb-8 {
        margin-bottom: 8px;
    }

    .iron-app-logo img {
        padding: 5px;
    }

    .hidden-mobile {
        display: none !important;
    }

    .hidden-desktop {
        display: block !important;
        margin: auto;
        margin-top: 22px;
    }

    .second-header .price-psi {
        display: none;
    }

    .second-header {
        display: inline-table;
    }
}

@media only screen and (min-width: 768px) and (max-width:1024px) {
    .iron-app-logo img {
        padding: 5px;
        /* width: 25% !important; */
    }

    .second-header {
        display: inline-table;
        width: 89%;
    }

    .mb-8 {
        margin-bottom: 8px;
    }

    .point-buck {
        width: 95%;
        margin: auto !important;
    }

    .hidden-mobile {
        display: none !important;
    }

    .hidden-desktop {
        display: block !important;
        margin: auto;
        margin-top: 22px;
    }

    .second-header .price-psi {
        display: none;
    }

    .bg-primary .container {
        margin-left: 0px;
    }

    .second-header .container {
        margin-left: 0px;
    }

    .second-header .srch-icon {
        width: 95%;
        margin: auto;
    }
}

@media (min-width: 360px) and (max-width: 970px) {
    .logoImage {
        width: 80px;
        margin-bottom: 20px;
    }

    .userOptions {
        position: absolute;
        right: 40px;
        /* top: 35px; */
    }

    .MuiGrid-spacing-xs-32-62 {
        width: 100%;
    }

    .second-header {
        width: 89%;
    }

    .login-logo {
        justify-content: flex-start !important;
        margin-top: 20px;
        margin-bottom: -20px;
    }
}


.point-buck {
    display: flex;
    height: 52px;
}

.point-buck.half {
    width: 45%;
}

.point-buck .buck {
    width: 100%;
    text-align: center;
    background: white;
    padding: 9px 3px;
    border-radius: 0px 5px 5px 0px;
    color: var(--active);
}

.point-buck.half .buck {
    border-radius: 5px;
}

.point-buck .point {
    width: 100%;
    text-align: center;
    background: var(--active);
    padding: 9px 3px;
    color: white;
    border-radius: 5px 0px 0px 5px;
}

.point-buck.half .point {
    border-radius: 5px
}

.point-buck .only-points {
    width: 100%;
    text-align: center;
    background: var(--active);
    padding: 9px 3px;
    color: white;
    border-radius: 5px 5px 5px 5px;
}

.point-buck .only-points p {
    margin: 0px;
    color: white;
    font-size: 16px;
}

.point-buck .only-points span {
    font-size: 11px;
}

.point-buck .point p {
    margin: 0px;
    color: white;
    font-size: 16px;
}

.point-buck .point span {
    font-size: 11px;
}

.point-buck .buck span {
    font-size: 11px;
}

.point-buck .buck p {
    margin: 0px;
    color: var(--active);
    font-size: 16px;
}

.second-header .point-buck {
    margin-top: 2px;
}

/* @media only screen and (min-width: 768px) and (max-width:1024px) {
    .prod-slider .container {
        margin-left: 5px;
    }
} */

.iron-header-menu li a {
    padding: 0 0.6rem !important;
}

.msg-error {
    position: absolute;
    left: 0%;
    padding: 8px;
    background: #ffffffdb;
    top: 15%;
    width: 25%;
    cursor: pointer;
    border-radius: 3px;
    transition: 0.3s;
    height: 2em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.msg-error:hover {
    background: #f0efefdb;
}

.msg-error:hover p {
    color: var(--active);
}

.msg-error p {
    margin: 0px;
    color: var(--active);
}






.budget-dropdown-content i.fa-shopping-basket {
    color: green;
    font-weight: normal;
    font-size: 20px;
    margin: 0px 12px;
}

.budget-dropdown-content i.fa-tag {
    color: red;
    font-weight: normal;
    font-size: 20px;
    margin: 0px 12px;
}

.budget-dropdown label {
    /* / background: #ededed; / */
    /* / padding: 10px 20px; / */
    width: 100%;
    border-radius: 5px;
    margin: 0;
}

.budget-dropdown div.budget-text {
    color: #4a4a4a;
    background: #ededed;
    display: flex;
    align-items: center;
    font-size: 13px;
    padding: 0 8px;
    margin: auto;
    /* margin: 13px 0px 0px -16px; */
    font-weight: bold;
    /* transform: rotate(-90deg); */
    height: 30px;
    border-radius: 3px;
}

.budget-list {
    width: 80%;
}

.budget-dropdown {
    position: absolute;
    text-align: center;
    right: 1%;
    display: flex;
    z-index: 999;
    top: 0px;
    padding: 0px 10px;
    background: white;
    width: 25% !important;
    margin: 5px;
}
.new-budget{
    display: none;
    position: absolute;
    top: 80%;
    background: white;
    width: 85%;
    left: 15%;
    box-shadow: 0px 0px 7px #7e7c7c;
    border-radius: 0px 0px 5px 5px;
}
.practice-budget-parent{
    position: relative;
}
.practice-budget{
    top: 0 !important;
    width: 85% !important;
    z-index: 10;
}
.practice-budget label{
    width: 94% !important;
}
.new-budget label{
    text-align: left;
    color: #817d7d;
    display: block;
    width: 94%;
    padding: 10px;
    background: #f2f2f2;
    font-size: 14px;
    font-weight: bold;
}
.new-budget div{
    display: flex;
    padding: 10px;
}
.new-budget div p{
    margin: 0px;
    width: 50% !important;
    text-align: left;
    color: #283593;
    font-weight: bold;
}
.new-budget div p b{
    color: #fe5722;
}

.prg-bar:hover .new-budget{
    display: block !important;
}

.budget-dropdown .prg-bar {
    background: white;
    margin: 10px auto;
    color: var(--active);
}

.budget-dropdown .align-center {
    margin: 25px auto;
}

.budget-dropdown .prg-bar .main-prog-line {
    height: 5px !important;
}

.budget-dropdown img {
    max-width: 85px;
    margin-right: 10px;
}

.budget-dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-left: 10px;
    width: 427px;
    padding: 12px 16px;
}

.budget-dropdown-content a {
    color: black;
    /* / padding: 12px 16px; */
    text-decoration: none;
    /* / display: block; */
}

.budget-dropdown-content a:hover {
    text-decoration: none;
}

.budget-dropdown:hover .budget-dropdown-content {
    display: block;
    padding: 10px;
}

.main-panel .cart-btn a:hover {
    background: red;
    color: white;
}

.hover-detail {}

.hover-detail table {
    background: #efeeee;
}

.hover-detail tr th {
    background: #333f98;
    color: white;
    padding: 10px;
    border: 1px solid lightgray;
}

.hover-detail tr td {
    padding: 10px;
    color: #000;
    border: 1px solid lightgray;
}

.cart-popup {
    position: absolute;
    background: white;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0px 0px 10px #909090b0;
    z-index: 1000;
    right: 0px;
    top: 100%;
    transition: 0.3s;
    display: none;
}

a.icon-cart-btn:hover .cart-popup {
    display: block !important;
}

a.icon-cart-btn:hover .empty-cart-popup {
    display: block;
}

.empty-cart-popup {
    position: absolute;
    background: white;
    padding: 0px 10px;
    border-radius: 6px;
    box-shadow: 0px 0px 10px #909090b0;
    z-index: 100;
    right: 0px;
    top: 115%;
    transition: 0.3s;
    width: 145px;
    display: none;
}

.empty-cart-popup h1 {
    display: flex;
    font-size: 18px;
    align-items: center;
    margin-bottom: 0px;
}

.empty-cart-popup h1 img {
    width: 22%;
    margin-right: 10px;
}

.custom-progress-bar > div {
    color: var(--active) !important;
    height: 10px !important;
}

.search-box-container {
  display: flex;
  align-items: center;
}

.search-box-container div {
  width: 100%;
}

.company-search-box-container {
  display: flex;
  align-items: center;
}

.company-search-box-container div {
  width: 100%;
}
