//admin panel style
.admin-wrapper{
   .rct-page-content{
      height: 100%;
      overflow-x: hidden;
   }
   .button {
      height:40px;
      span{
         letter-spacing: 0;
      }
   }
   .search-box-wrap{
      .search-box{
         width:calc(100% - 160px);
      }
   }
   .iron-form-input-wrap {
      margin-bottom: 0 !important;
   }
   .projects-icon{
      .btn-icon{
         height: 40px;
         width: 40px;
         min-width: auto;
         margin-right: 10px;
         border-radius: 100%;
      }
      .active{
         opacity: 0.4;
      }
   }
   .product-list-wrap{
      .ReactTable{
         .rt-tbody {
            .rt-td{
               &:nth-child(3){
                  color:$primary;
               }
            }
         }
      }
   }
   .iron-user-info-wrap{
      .iron-deactivate-wrap{
         fieldset{
            > div{
               display: flex;
               label{
                  > span{
                     &:first-child{
                        padding: 0;
                     }
                  }
               }
            }
         }
      }
   }
}
//invoice dialog box width
.admin-invoice-wrap{
   >div{
      &:nth-child(2){
         >div{
            max-width:700px;
         }
      }
   }
}
// user dialog box width
.add-user-dialog{
   > div:nth-child(2){
      >div{
         width:100%;
      }
   }
}
//react table style
.product-list-wrap{
   .ReactTable{
      .rt-tbody{
         .rt-tr-group{
            cursor: pointer;
         }
      }
   }
}
.ReactTable{
   border: none;
   img{
      border-radius: 0;
   }
   .rt-thead{
      border-bottom:1px solid rgba(0, 0, 0, 0.12);
      box-shadow: none !important;
      .rt-tr{
         text-align: left;
         .rt-th{
            padding: 15px;
            border-right: none;
            font-size:12px;
            font-weight: 500;
            line-height: 2.5;
            text-transform: capitalize;
         }
      }
   }
   .rt-tbody {
      .rt-tr-group{
         border-bottom:1px solid rgba(0, 0, 0, 0.12);
      }
      .rt-td{
         padding: 15px;
         border-right: none;
         font-size:14px;
         line-height: 2.5;
         .action-btn {
            height: 35px;
            width: 35px;
            min-width: auto;
            margin-right: 10px;
            border-radius: 100%;
         }
      }
   }
   .pagination-bottom{
      .-pagination{
         padding: 10px;
         box-shadow: none !important;
         .-previous,.-next{
            button{
               background-color: $primary !important;
               color:#fff !important;
               font-weight: 500;
            }
         }
      }
   }
}
//upload image section style
.user-image-upload{
   width:70px;
   height:70px;
   position: relative;
   .image-content{
      position: absolute;
      top:0;
      left:0;
      right:0;
      bottom: 0;
   }
   .fileUploader {
      height: 100%;
      .fileContainer{
         padding: 0;
         height: 100%;
         input{
            width: 100%;
         }
         p{
            display:none;
         }
         .deleteImage{
            top: 0;
            right: 0;
            background:$active;
            font-size: 14px;
            line-height: 20px;
            width: 20px;
            height: 20px;
         }
      }
      .fileContainer,.chooseFileButton{
         background-color: transparent !important;
         margin: 0;
         box-shadow:none;
      }
      .chooseFileButton{
         width: 100%;
         height: 45px;
         padding: 5px;
         &:after{
            font-family: 'Material-Design-Iconic-Font';
            content: "\f158";
            background-color: $base;
            height: 30px;
            width: 30px;
            color:$primary;
            display: block;
            border-radius: 100%;
            line-height: 30px;
            font-size: 20px;
            margin: 0 auto;
         }
      }
      .uploadPicturesWrapper{
         position: absolute;
         top: 0;
         left: 0;
         right: 0;
         bottom: 0;
         pointer-events:none;
         .uploadPictureContainer{
            height: 100%;
            width: 100%;
            margin: 0;
            padding: 0;
            pointer-events:auto;
            .uploadPicture{
               border-radius: 0;
            }
         }
      }
   }
}
//simple table styling
.Transaction-table-wrap{
   header{
      background-color: #fff;
      box-shadow: none;
      border-bottom:1px solid rgba(224, 224, 224, 1);
   }
   .transaction-box{
      >div{
         //overflow-x: scroll !important;
         padding: 0 0 10px !important;
      }
   }
   table{
      th,td{
         text-transform: capitalize;
         white-space: nowrap;
         padding: 4px 20px 4px 20px;
      }
      td{
         font-size:0.875rem;
         >div{
            span{
               font-size: 0.875rem;
               font-weight:500;
            }
         }
      }
   }
}
// product detil page gallery style
.iron-product-add-wrap,.iron-product-edit-wrap{
   .iron-product-gallery{
       .product-gallery-nav{
           .product-gallery-item{
               display: block;
               position: absolute;
               overflow: hidden;
               >div{
                   top: 0;
                   left: 0;
                   right: 0;
                   bottom: 0;
                   position: absolute;
                   display: flex;
                    display: -ms-flexbox;
                    justify-content: center;
                    -ms-flex-pack: center;
                    align-items: center;
                    -ms-flex-align: center;
                   height: 100%;
                   padding: 0;
                   margin: 0;
                   top: 0;
               }
           }
       }
       .product-gallery-nav{
           padding-bottom: calc(((5 * ((100% - 0px) * 1)) + 0px) + 80px);
           position: relative;
           display: block;
           .product-gallery-item{
               left: 0px;
               width: calc(((100% - 0px) * 1) + 0px);
               margin-top: 0px;
               padding-top: calc(((100% - 0px) * 1) + 0px);
               &:nth-child(2){
                   margin-top: calc(((100% - 0px) + 20px) * 1);
               }
               &:nth-child(3){
                   margin-top: calc(((100% - 0px) + 20px) * 2);
               }
               &:nth-child(4){
                   margin-top: calc(((100% - 0px) + 20px) * 3);
               }
               &:nth-child(5){
                   margin-top: calc(((100% - 0px) + 20px) * 4);
               }
           }
       }
   }
   .add-product-input{
      width:100%;
      font-size:14px;
      &:before,&:after{
         display: none;
      }
      textarea{
         font-size: 14px;
      }
      >div{
         padding-top:0;
         &:before,&:after{
            display: none;
         }
      }
      input{
         padding-top:0;
         text-transform: capitalize;
      }
   }
   .add-text,
   .edit-text{
      position: relative;
      padding-left:23px;
      &:after{
         font-family: 'Material-Design-Iconic-Font';
         content: "\f158";
         position: absolute;
         top: 1px;
         left: 3px;
         font-weight:700;
         font-size: 12px;
         color: #283593;
      }
   }
   .add-text{
      &:after {
         content: "\f278";
         font-size:13px;
      }
   }
   .text-h3{
      font-weight: 500;
      font-size: 2.125rem;
   }
   .text-h4{
      font-weight: 500;
      font-size:1.5rem;
   }
   .text-h5{
      font-size:1.125rem;
   }
   .active-input{
      color:$active;
   }
   .iron-select-width2{
      width: 250px;
   }
   .image-upload{
      position: relative;
      .image-content{
         position: absolute;
         top:0;
         left:0;
         right:0;
         bottom:0;
         transition:all 0.3s ease-in-out;
      }
      &:hover{
         .image-content{
            background-color: rgba(0,0,0,0.5);
         }
      }
   }
   .fileUploader {
      height: 100%;
      .fileContainer{
         padding: 0;
         height: 100%;
         input{
            width: 100%;
         }
         p{
            display:none;
         }
         .deleteImage{
            top: 0;
            right: 0;
            background:$active;
            font-size: 14px;
            line-height: 20px;
            width: 20px;
            height: 20px;
         }
      }
      .fileContainer,.chooseFileButton{
         background-color: transparent !important;
         margin: 0;
         box-shadow:none;
      }
      .chooseFileButton{
         width: 100%;
         height: 45px;
         padding: 5px;
         &:after{
            font-family: 'Material-Design-Iconic-Font';
            content: "\f158";
            color:$primary;
            background-color: $base;
            height: 30px;
            width: 30px;
            display: block;
            border-radius: 100%;
            line-height: 30px;
            font-size: 20px;
            margin: 0 auto;
         }
      }
      .add-Button{
         &:after {
            content: "\f278";
         }
      }
      .uploadPicturesWrapper{
         position: absolute;
         top: 0;
         left: 0;
         right: 0;
         bottom: 0;
         pointer-events:none;
         .uploadPictureContainer{
            height: 100%;
            width: 100%;
            margin: 0;
            padding: 0;
            pointer-events:auto;
         }
      }
   }
}
//badge style
.badge-primary{
   background-color:$primary !important;
   color: #fff !important;
}
.badge-secondary{
   background-color: $active !important;
   color: #fff !important;
}
.badge-danger{
   background-color: #f44336 !important;
   color: #fff !important;
}
.admin-collaboration{
   img{
      border-radius: 0;
   }
}
// collaboration table style
.table-wrap{
   thead,tbody{
      th,td{
         text-transform: capitalize;
      }
   }
   .btn-icon{
      width:40px;
      height:40px;
      min-width: 40px;
      border-radius:100%;
   }
}
@media(max-width:1366px){
   .admin-collaboration{
      overflow-x: scroll;
   }
}
//responsive section start
@media(max-width:1199px){
   .admin-wrapper{
      >div{
         >div{
            &:first-child{
               z-index: 999 !important;
            }
            &:nth-child(2){
               z-index: 998 !important;
            }
         }
      }
   }
   .Transaction-table-wrap {
      .transaction-box {
         > div {
            overflow-x: scroll !important;
         }
      }
   }
}
@media(max-width:959px){
   .admin-wrapper{
      .product-gallery-item{
         img{
            width: 100%;
         }
      }
   }

}
@media(max-width:599px){
   .admin-invoice-wrap{
      >div{
         &:nth-child(2){
            >div{
               max-width:80vh;
               margin:20px;
            }
         }
      }
      h3{
         font-size: 1.5rem;
      }
      h4{
         font-size: 1.25rem;
      }
      h5{
         font-size:1rem;
      }
      #payment-receipt{

         img{
            height: 50px;
            width: 50px;
         }
      }
      .btn-lg{
         min-height: 2.5rem !important;
         padding: 0 10px !important;
      }
   }
   .ReactTable {
      .rt-tbody,.rt-thead {
         .rt-td,.rt-th {
            padding: 10px;
        }
      }
      .pagination-bottom{
         .-pagination{
            display: block !important;
            padding: 10px 0;
         }
      }
   }
   .admin-wrapper{
      .search-box-wrap{
         >div{
            width: 100%;
         }
         .search-box{
            width:100%;
         }
      }
   }
   .iron-reports-wrap{
      .app-card-title{
         padding-bottom:0 ;
      }
   }
   .iron-product-add-wrap,.iron-product-edit-wrap{
      .add-product-input,.iron-select-width2{
         width: 90% !important;
         input,textarea{
            width:99%;
         }
      }
   }
}
@media(max-width:449px){
   .admin-invoice-wrap{
      .btn-lg.mr-20{
         margin-right:0 !important;
      }
   }
}
@media(max-width:374px){
   .ReactTable {
      .pagination-bottom{
         .-pagination{
            .-center{
               margin: 10px 0;
            }
         }
      }
   }
}
