.support-input-wrap {
    /* width: 35% !important; */
    /* top: 20% !important; */
    /* position: absolute !important; */
    background: #313e95 e !important;
    z-index: 100;
    /* box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12), 0 16px 16px 0 rgba(0, 0, 0, 0.24) !important; */
}

.input-wrap {
    /* width: 42% !important; */
    /* top: 20% !important; */
    /* position: absolute !important; */
    background: #313e95 e !important;
    z-index: 100;
    /* box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12), 0 16px 16px 0 rgba(0, 0, 0, 0.24) !important; */
}

.company-input-wrap {
    /* width: 20% !important; */
    /* top: 20% !important; */
    /* position: absolute !important; */
    background: #313e95 e !important;
    z-index: 100;
    /* box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12), 0 16px 16px 0 rgba(0, 0, 0, 0.24) !important; */
}

.col-8 {
    text-align: start !important;
    width: 70%;
}

.searched-product>ul li {
    /* width: 100%; */
    color: black;
    padding: 1rem;
}

.product-name {
    text-align: start !important;
}

.react-autosuggest__input {
    width: 90% !important;
    font-size: 1.3rem !important;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #000;
}

ul {
    list-style-type: none;
}

.cart-btn {
    /* background-color: #FF5722 !important; */
    color: #FF5722 !important;
}

.my-title .cart-btn MuiButton-label-181 {
    font-size: 10px;
}

.product-description {
    font-weight: 600 !important;
    padding: 1rem;
    text-align: start !important;
}

.column-head {
    font-size: 14px;
    color: #9191dd;
    font-weight: 500;
}

/* .product-list{
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
} */

.search-form {
    position: relative;
}

.iron-search-box .search-form .input-wrap .company-input-wrap {
    padding: 5px;
    border-radius: 5px;
    color: gray;
    font-size: 15px;
}

.main-search .react-autosuggest__input {
    border: none !important;
    font-size: 12px !important;
    padding: 10px;
    background: #ffffff;
    border-radius: 50px;
    width: 95% !important;
}

.main-search .react-autosuggest__input::placeholder {
    color: rgb(130, 128, 128) !important;
    width: 100%;
}
.iron-fixed-header .react-autosuggest__input::placeholder{
    width: 50%;
}

.main-search .react-autosuggest__suggestions-container {
    background: white;
}

.loading-gif {
    position: absolute !important;
    top: 17px;
    right: 8%;
}
.search-btn {
    position: absolute !important;
    top: 5px;
    right: 20px;
}

.search-btn .material-icons {
    position: absolute;
    z-index: 100;
    top: 0px !important;
    /* right: -5px !important; */
    /* left: 15px !important; */
    padding-left: 7px;
    color: rgb(130, 128, 128);
}

.company.search-btn .material-icons {
    position: absolute;
    z-index: 100;
    top: 0px !important;
    padding-left: 7px;
    color: rgb(130, 128, 128);
}

.search-btn .MuiIconButton-label-172 {
    color: #313e95;
    padding: 5px;
    font-weight: bold;
    left: 15px;
    /* top: 17px; */
    position: absolute !important;
    z-index: 1000 !important;
}

.my-title ul.title {
    padding: 10px;
    margin-bottom: 0px;
    background: #313e95;
    color: white;
    font-size: 13px;
    text-align: center;
    border: 4px solid;
}

.my-title ul.title li:nth-last-child(1) {
    border-right: none;
}

.my-title ul.title li {
    border-right: 1px solid;
    color: white;
}

.my-title ul .product-description a {
    color: #595756;
    font-weight: normal;
    font-size: 13px;
}

.my-title ul .product-description {
    padding: 4px 15px;
}

/* .my-title .searched-product{
    background: #f2f2f2;
margin: 4px 0px;
border: none !important;
} */

.my-title .searched-product ul li {
    /* font-weight: 500; */
    /* opacity: 0.6; */
}

.ais-InstantSearch__root>div {
    width: 100% !important;
}

.MuiButton-label-191 {
    font-size: 10px;
}

.MuiAppBar-colorPrimary-8 {
    font-size: 13px;
}

.suggestionContainer .rcnt-title {
    background: #e6e9ff;
    border-radius: 10px;
}

.my-title ul {
    margin-bottom: 0px;
}

.my-title ul li {
    background: rgb(255, 255, 255);
    border-bottom: 1px solid #f7f7f7;
    transition: 0.3s;
}

.my-title ul li:hover {
    background: rgb(243, 243, 243);
}

.my-title ul li:hover ul li {
    background: rgb(243, 243, 243) !important;
}

.my-title ul li:hover ul li a {
    color: #FF5722 !important;
}
