body {
  overflow-x: unset !important;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}

.pagination > li {
  display: inline;
}

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #000;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  z-index: 2;
  color: #23527c;
  background-color: #eee;
  border-color: #ddd;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #ff5722;
  border-color: #ff5722;
}

.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}

.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}

.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.dialog-demo .p-button {
  margin: 0 0.5rem 0 0;
  min-width: 10rem;
}

.dialog-demo p {
  margin: 0;
  line-height: 1.5;
}

.dialog-demo .confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-demo .p-dialog .p-button {
  min-width: 6rem;
}

.select-dropdown .css-4ljt47-MenuList {
  max-height: 580px;
}
.address-select-dropdown .css-26l3qy-menu {
  position: initial !important;
}
body .p-dialog {
  padding: 0;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-dialog .p-dialog-titlebar {
  border: 1px solid #c8c8c8;
  background-color: #f4f4f4;
  color: #333333;
  padding: 1em;
  font-weight: 700;
  border-bottom: 0 none;
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
}

body .p-dialog .p-dialog-titlebar .p-dialog-title {
  margin: 0;
  float: none;
  font-weight: 700;
}

body .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon {
  color: #848484;
  border: 0 none;
  padding: 0;
  margin-left: 0.5em;
  -moz-transition: color 0.2s, box-shadow 0.2s;
  -o-transition: color 0.2s, box-shadow 0.2s;
  -webkit-transition: color 0.2s, box-shadow 0.2s;
  transition: color 0.2s, box-shadow 0.2s;
}

body .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon:hover {
  color: #333333;
}

body .p-dialog .p-dialog-content {
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 1em;
}

body .p-dialog .p-dialog-footer {
  border: 1px solid #c8c8c8;
  border-top: 0 none;
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em;
  margin: 0;
  text-align: right;
}

body .p-dialog .p-dialog-footer button {
  margin: 0 0.5em 0 0;
  width: auto;
}

body .p-dialog.p-confirm-dialog .p-dialog-content {
  padding: 1.5em;
}

body .p-dialog.p-confirm-dialog .p-dialog-content > span {
  float: none;
  display: inline-block;
  vertical-align: middle;
  line-height: 14px;
  margin: 0;
}

body .p-dialog.p-confirm-dialog .p-dialog-content > span.p-icon {
  margin-right: 0.35em;
  font-size: 16px;
}

body .p-multiselect {
  background: #ffffff;
  border: 1px solid #a6a6a6;
  -moz-transition: border-color 0.2s, box-shadow 0.2s;
  -o-transition: border-color 0.2s, box-shadow 0.2s;
  -webkit-transition: border-color 0.2s, box-shadow 0.2s;
  transition: border-color 0.2s, box-shadow 0.2s;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

body .p-multiselect:not(.p-disabled):hover {
  border-color: #212121;
}

body .p-multiselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
  -moz-box-shadow: 0 0 0 0.2em #8dcdff;
  box-shadow: 0 0 0 0.2em #8dcdff;
  border-color: #007ad9;
}

body .p-multiselect .p-multiselect-label {
  padding: 0.429em;
  padding-right: 2em;
  font-weight: 400;
  color: #333333;
}

body .p-multiselect .p-multiselect-trigger {
  background-color: #ffffff;
  width: 2em;
  line-height: 2em;
  text-align: center;
  padding: 0;
  color: #848484;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

body .p-multiselect-panel {
  padding: 0;
  border: 1px solid #c8c8c8;
  background-color: #ffffff;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-multiselect-panel .p-multiselect-header {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border-bottom: 1px solid #eaeaea;
  color: #333333;
  background-color: #ffffff;
  margin: 0;
}

body
  .p-multiselect-panel
  .p-multiselect-header
  .p-multiselect-filter-container {
  float: none;
  width: 70%;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0;
}

body
  .p-multiselect-panel
  .p-multiselect-header
  .p-multiselect-filter-container
  .p-inputtext {
  padding: 0.429em;
  padding-right: 2em;
}

body
  .p-multiselect-panel
  .p-multiselect-header
  .p-multiselect-filter-container
  .p-multiselect-filter-icon {
  color: #007ad9;
  top: 50%;
  margin-top: -0.5em;
  right: 0.5em;
  left: auto;
}

body .p-multiselect-panel .p-multiselect-header .p-checkbox {
  margin-right: 0.5em;
  float: none;
  vertical-align: middle;
}

body .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  color: #848484;
  top: 50%;
  margin-top: -0.5em;
  -moz-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  -webkit-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
}

body .p-multiselect-panel .p-multiselect-items {
  padding: 0;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  padding: 0.429em 0.857em;
  border: 0 none;
  color: #333333;
  background-color: transparent;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body
  .p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #333333;
  background-color: #eaeaea;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: inset 0 0 0 0.2em #8dcdff;
  -moz-box-shadow: inset 0 0 0 0.2em #8dcdff;
  box-shadow: inset 0 0 0 0.2em #8dcdff;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #ffffff;
  background-color: #007ad9;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  position: static;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0.5em 0 0;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item label {
  display: inline-block;
  vertical-align: middle;
}

body .p-checkbox {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  width: 20px;
  height: 20px;
}

body .p-checkbox .p-checkbox-box {
  border: 1px solid #a6a6a6;
  background-color: #ffffff;
  width: 20px;
  height: 20px;
  text-align: center;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

body .p-checkbox .p-checkbox-box:not(.p-disabled):hover {
  border-color: #212121;
}

body .p-checkbox .p-checkbox-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
  -moz-box-shadow: 0 0 0 0.2em #8dcdff;
  box-shadow: 0 0 0 0.2em #8dcdff;
  border-color: #007ad9;
}

body .p-checkbox .p-checkbox-box.p-highlight {
  border-color: #007ad9;
  background-color: #007ad9;
  color: #ffffff;
}

body .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  border-color: #005b9f;
  background-color: #005b9f;
  color: #ffffff;
}

body .p-checkbox .p-checkbox-box .p-checkbox-icon {
  overflow: hidden;
  position: relative;
  font-size: 18px;
}

body .p-checkbox-label {
  margin: 0 0 0 0.5em;
}

body .p-paginator {
  background-color: #f4f4f4;
  border: 1px solid #c8c8c8;
  padding: 0;
}

body .p-paginator .p-paginator-first,
body .p-paginator .p-paginator-prev,
body .p-paginator .p-paginator-next,
body .p-paginator .p-paginator-last {
  color: #848484;
  height: 2.286em;
  min-width: 2.286em;
  border: 0 none;
  line-height: 2.286em;
  padding: 0;
  margin: 0;
  vertical-align: top;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
body .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
body .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
body .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background-color: #e0e0e0;
  color: #333333;
}

body .p-paginator .p-dropdown {
  border: 0 none;
}

body .p-paginator .p-dropdown .p-dropdown-trigger,
body .p-paginator .p-dropdown .p-dropdown-label {
  color: #848484;
}

body .p-paginator .p-dropdown:hover .p-dropdown-trigger,
body .p-paginator .p-dropdown:hover .p-dropdown-label {
  color: #333333;
}

body .p-paginator .p-paginator-first:before {
  position: relative;
  top: 1px;
}

body .p-paginator .p-paginator-prev:before {
  position: relative;
}

body .p-paginator .p-paginator-next:before {
  position: relative;
  top: 1px;
}

body .p-paginator .p-paginator-last:before {
  position: relative;
  top: 1px;
}

body .p-paginator .p-paginator-current {
  vertical-align: top;
  display: inline-block;
  height: 2.286em;
  min-width: 2.286em;
  border: 0 none;
  line-height: 2.286em;
}

body .p-paginator .p-paginator-pages {
  vertical-align: top;
  display: inline-block;
  padding: 0;
}

body .p-paginator .p-paginator-pages .p-paginator-page {
  color: #848484;
  height: 2.286em;
  min-width: 2.286em;
  border: 0 none;
  line-height: 2.286em;
  padding: 0;
  margin: 0;
  vertical-align: top;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: #007ad9;
  color: #ffffff;
}

body .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background-color: #e0e0e0;
  color: #333333;
}

body .p-paginator .p-dropdown {
  margin-left: 0.5em;
  height: 2.286em;
  min-width: auto;
}

body .p-datatable.p-datatable-responsive .p-paginator-top {
  border-bottom: 1px solid #c8c8c8;
}

body .p-datatable.p-datatable-responsive .p-paginator-bottom {
  border-top: 1px solid #c8c8c8;
}

body .p-tabview.p-tabview-top,
body .p-tabview.p-tabview-bottom,
body .p-tabview.p-tabview-left,
body .p-tabview.p-tabview-right {
  border: 0 none;
}

body .p-tabview.p-tabview-top .p-tabview-nav,
body .p-tabview.p-tabview-bottom .p-tabview-nav,
body .p-tabview.p-tabview-left .p-tabview-nav,
body .p-tabview.p-tabview-right .p-tabview-nav {
  padding: 0;
  background: transparent;
  border: 0 none;
}

body .p-tabview.p-tabview-top .p-tabview-nav li a,
body .p-tabview.p-tabview-bottom .p-tabview-nav li a,
body .p-tabview.p-tabview-left .p-tabview-nav li a,
body .p-tabview.p-tabview-right .p-tabview-nav li a {
  border: 1px solid #c8c8c8;
  background-color: #f4f4f4;
  float: none;
  display: inline-block;
  color: #333333;
  padding: 0.571em 1em;
  font-weight: 700;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
}

body .p-tabview.p-tabview-top .p-tabview-nav li a .p-tabview-left-icon,
body .p-tabview.p-tabview-bottom .p-tabview-nav li a .p-tabview-left-icon,
body .p-tabview.p-tabview-left .p-tabview-nav li a .p-tabview-left-icon,
body .p-tabview.p-tabview-right .p-tabview-nav li a .p-tabview-left-icon {
  margin-right: 0.5em;
}

body .p-tabview.p-tabview-top .p-tabview-nav li a .p-tabview-right-icon,
body .p-tabview.p-tabview-bottom .p-tabview-nav li a .p-tabview-right-icon,
body .p-tabview.p-tabview-left .p-tabview-nav li a .p-tabview-right-icon,
body .p-tabview.p-tabview-right .p-tabview-nav li a .p-tabview-right-icon {
  margin-left: 0.5em;
}

body .p-tabview.p-tabview-top .p-tabview-nav li a:not(.p-disabled):focus,
body .p-tabview.p-tabview-bottom .p-tabview-nav li a:not(.p-disabled):focus,
body .p-tabview.p-tabview-left .p-tabview-nav li a:not(.p-disabled):focus,
body .p-tabview.p-tabview-right .p-tabview-nav li a:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
  -moz-box-shadow: 0 0 0 0.2em #8dcdff;
  box-shadow: 0 0 0 0.2em #8dcdff;
}

body .p-tabview.p-tabview-top .p-tabview-nav li .p-tabview-close,
body .p-tabview.p-tabview-bottom .p-tabview-nav li .p-tabview-close,
body .p-tabview.p-tabview-left .p-tabview-nav li .p-tabview-close,
body .p-tabview.p-tabview-right .p-tabview-nav li .p-tabview-close {
  color: #848484;
  margin: 0 0.5em 0 0;
  vertical-align: middle;
}

body
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li:not(.p-highlight):not(.p-disabled):hover
  a,
body
  .p-tabview.p-tabview-bottom
  .p-tabview-nav
  li:not(.p-highlight):not(.p-disabled):hover
  a,
body
  .p-tabview.p-tabview-left
  .p-tabview-nav
  li:not(.p-highlight):not(.p-disabled):hover
  a,
body
  .p-tabview.p-tabview-right
  .p-tabview-nav
  li:not(.p-highlight):not(.p-disabled):hover
  a {
  background-color: #dbdbdb;
  border: 1px solid #dbdbdb;
  color: #333333;
}

body
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li:not(.p-highlight):not(.p-disabled):hover
  .p-tabview-close,
body
  .p-tabview.p-tabview-bottom
  .p-tabview-nav
  li:not(.p-highlight):not(.p-disabled):hover
  .p-tabview-close,
body
  .p-tabview.p-tabview-left
  .p-tabview-nav
  li:not(.p-highlight):not(.p-disabled):hover
  .p-tabview-close,
body
  .p-tabview.p-tabview-right
  .p-tabview-nav
  li:not(.p-highlight):not(.p-disabled):hover
  .p-tabview-close {
  color: #333333;
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight a,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight a,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight a,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight a {
  background-color: #007ad9;
  border: 1px solid #007ad9;
  color: #ffffff;
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight .p-tabview-close,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight .p-tabview-close,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight .p-tabview-close,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight .p-tabview-close {
  color: #ffffff;
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight:hover a,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight:hover a,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight:hover a,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight:hover a {
  border: 1px solid #005b9f;
  background-color: #005b9f;
  color: #ffffff;
}

body
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li.p-highlight:hover
  a
  .p-tabview-left-icon,
body
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li.p-highlight:hover
  a
  .p-tabview-right-icon,
body
  .p-tabview.p-tabview-bottom
  .p-tabview-nav
  li.p-highlight:hover
  a
  .p-tabview-left-icon,
body
  .p-tabview.p-tabview-bottom
  .p-tabview-nav
  li.p-highlight:hover
  a
  .p-tabview-right-icon,
body
  .p-tabview.p-tabview-left
  .p-tabview-nav
  li.p-highlight:hover
  a
  .p-tabview-left-icon,
body
  .p-tabview.p-tabview-left
  .p-tabview-nav
  li.p-highlight:hover
  a
  .p-tabview-right-icon,
body
  .p-tabview.p-tabview-right
  .p-tabview-nav
  li.p-highlight:hover
  a
  .p-tabview-left-icon,
body
  .p-tabview.p-tabview-right
  .p-tabview-nav
  li.p-highlight:hover
  a
  .p-tabview-right-icon {
  color: #ffffff;
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-tabview-selected a,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-tabview-selected a,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-tabview-selected a,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-tabview-selected a {
  cursor: pointer;
}

body .p-tabview.p-tabview-top .p-tabview-nav {
  margin-bottom: -1px;
}

body .p-tabview.p-tabview-top .p-tabview-nav li a {
  margin-right: 2px;
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
}

body .p-tabview.p-tabview-bottom .p-tabview-nav {
  margin-top: -1px;
}

body .p-tabview.p-tabview-bottom .p-tabview-nav li a {
  margin-right: 2px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

body .p-tabview.p-tabview-left .p-tabview-nav {
  margin-right: -px;
}

body .p-tabview.p-tabview-left .p-tabview-nav li a {
  margin-bottom: 2px;
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

body .p-tabview.p-tabview-right .p-tabview-nav {
  margin-right: -1px;
}

body .p-tabview.p-tabview-right .p-tabview-nav li a {
  margin-bottom: 2px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

body .p-tabview .p-tabview-panels {
  background-color: #ffffff;
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  color: #333333;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

body .p-tabview .p-tabview-panels .p-tabview-panel {
  padding: 0;
}

body .p-autocomplete .p-autocomplete-input {
  padding: 0.429em;
}

body
  .p-autocomplete.p-autocomplete-multiple
  .p-autocomplete-multiple-container {
  padding: 0.2145em 0.429em;
}

body
  .p-autocomplete.p-autocomplete-multiple
  .p-autocomplete-multiple-container:not(.p-disabled):hover {
  border-color: #212121;
}

body
  .p-autocomplete.p-autocomplete-multiple
  .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
  -moz-box-shadow: 0 0 0 0.2em #8dcdff;
  box-shadow: 0 0 0 0.2em #8dcdff;
  border-color: #007ad9;
}

body
  .p-autocomplete.p-autocomplete-multiple
  .p-autocomplete-multiple-container
  .p-autocomplete-input-token {
  margin: 0;
  padding: 0.2145em 0;
  color: #333333;
}

body
  .p-autocomplete.p-autocomplete-multiple
  .p-autocomplete-multiple-container
  .p-autocomplete-input-token
  input {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  padding: 0;
  margin: 0;
}

body
  .p-autocomplete.p-autocomplete-multiple
  .p-autocomplete-multiple-container
  .p-autocomplete-token {
  font-size: 14px;
  padding: 0.2145em 0.429em;
  margin: 0 0.286em 0 0;
  background: #007ad9;
  color: #ffffff;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

body .p-autocomplete-panel {
  padding: 0;
  border: 1px solid #c8c8c8;
  background-color: #ffffff;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

body .p-autocomplete-panel .p-autocomplete-items {
  padding: 0;
}

body .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-list-item {
  margin: 0;
  padding: 0.429em 0.857em;
  border: 0 none;
  color: #333333;
  background-color: transparent;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

body
  .p-autocomplete-panel
  .p-autocomplete-items
  .p-autocomplete-list-item:hover {
  color: #333333;
  background-color: #eaeaea;
}

body
  .p-autocomplete-panel
  .p-autocomplete-items
  .p-autocomplete-list-item.p-highlight {
  color: #ffffff;
  background-color: #007ad9;
}

body .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-group {
  padding: 0.429em 0.857em;
  background-color: #d8dae2;
  color: #333333;
}

body .p-fluid .p-autocomplete .p-autocomplete-dropdown.p-button {
  width: 2.357em;
}

body
  .p-fluid
  .p-autocomplete.p-autocomplete-multiple.p-autocomplete-dd
  .p-autocomplete-multiple-container {
  border-right: 0 none;
  width: calc(100% - 2.357em);
}

body .p-fluid .p-autocomplete.p-autocomplete-dd .p-inputtext {
  border-right: 0 none;
  width: calc(100% - 2.357em);
}

body .p-inputtext {
  font-size: 14px;
  color: #333333;
  background: #ffffff;
  padding: 0.429em;
  border: 1px solid #a6a6a6;
  -moz-transition: border-color 0.2s, box-shadow 0.2s;
  -o-transition: border-color 0.2s, box-shadow 0.2s;
  -webkit-transition: border-color 0.2s, box-shadow 0.2s;
  transition: border-color 0.2s, box-shadow 0.2s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

body .p-inputtext:enabled:hover {
  border-color: #212121;
}

body .p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
  -moz-box-shadow: 0 0 0 0.2em #8dcdff;
  box-shadow: 0 0 0 0.2em #8dcdff;
  border-color: #007ad9;
}

body .p-fluid .p-autocomplete.p-autocomplete-dd .p-inputtext {
  border-right: 0 none;
  width: calc(100% - 2.357em);
}

body .p-chips > ul.p-inputtext {
  padding: 0.2145em 0.429em;
  display: inline-block;
}

body .p-chips > ul.p-inputtext:not(.p-disabled):hover {
  border-color: #212121;
}

body .p-chips > ul.p-inputtext:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
  -moz-box-shadow: 0 0 0 0.2em #8dcdff;
  box-shadow: 0 0 0 0.2em #8dcdff;
  border-color: #007ad9;
}

body .p-chips > ul.p-inputtext .p-chips-input-token {
  padding: 0.2145em 0;
}

body .p-chips > ul.p-inputtext .p-chips-input-token input {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  padding: 0;
  margin: 0;
  color: #333333;
}

body .p-chips > ul.p-inputtext .p-chips-input-token input:hover {
  border: 0 none;
}

body .p-chips > ul.p-inputtext .p-chips-input-token input:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: 0 none;
  border: 0 none;
}

body .p-chips > ul.p-inputtext .p-chips-token {
  font-size: 14px;
  padding: 0.2145em 0.429em;
  margin: 0 0.286em 0 0;
  background: #007ad9;
  color: #ffffff;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.p-dialog-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  pointer-events: none;
}

.p-dialog-wrapper-visible {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-dialog-wrapper.p-dialog-mask {
  pointer-events: auto;
}

.p-dialog {
  position: fixed;
  padding: 0;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  pointer-events: auto;
}

.p-dialog .p-dialog-titlebar {
  padding: 0.5em 0.75em;
  position: relative;
  border: 0;
}

.p-dialog .p-dialog-content {
  position: relative;
  border: 0;
  padding: 0.5em 0.75em;
  background: none;
  zoom: 1;
}

.p-dialog-resizable .p-dialog-content {
  overflow: auto;
}

.p-dialog .p-resizable-handle {
  width: 14px;
  height: 14px;
  right: 3px;
  bottom: 3px;
  position: absolute;
  font-size: 0.1px;
  display: block;
  cursor: se-resize;
}

.p-draggable .p-dialog-titlebar {
  cursor: move;
}

.p-dialog .p-dialog-titlebar-icons {
  float: right;
}

.p-dialog .p-dialog-titlebar-icons:after {
  content: "";
  display: table;
  clear: both;
}

.p-dialog .p-dialog-titlebar-icon {
  text-decoration: none;
  padding: 0.125em;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid transparent;
}

.p-dialog .p-dialog-titlebar-icon span {
  display: block;
  margin: 0;
}

.p-dialog-footer {
  padding: 1em;
  border-width: 1px 0 0 0;
  text-align: right;
}

.p-confirmdialog {
  width: 30em;
}

.p-confirmdialog.p-dialog .p-dialog-content {
  padding: 1em 2em;
}

.p-confirmdialog .p-dialog-content .p-confirmdialog-icon {
  font-size: 1.5em;
  vertical-align: middle;
  margin-right: 0.5em;
}

.p-confirmdialog .p-dialog-content .p-confirmdialog-message {
  vertical-align: middle;
}

.p-fluid .p-dialog-footer .p-button {
  width: auto;
}

.p-rtl .p-dialog .p-dialog-titlebar-close {
  float: left;
}

.p-rtl .p-dialog .p-dialog-footer {
  text-align: left;
}

@media screen and (max-width: 40em) {
  .p-confirmdialog {
    width: 90%;
  }
}

.p-dialog-enter {
  opacity: 0;
  transform: translateX(-50%) translateY(-50%) scale(0.7);
}

.p-dialog-enter-active {
  opacity: 1;
  transform: translateX(-50%) translateY(-50%) scale(1);
  transition: all 150ms cubic-bezier(0, 0, 0.2, 1);
}

.p-dialog-enter-done {
  position: static;
  transform: none;
  top: auto;
  left: auto;
}

.p-dialog-exit {
  opacity: 1;
}

.p-dialog-exit-active {
  opacity: 0;
  transition: all 75ms cubic-bezier(0.4, 0, 0.2, 1);
}

.p-dialog-maximized {
  -webkit-transition: none;
  transition: none;
  transform: none;
  width: 100vw !important;
  top: 0;
  left: 0;
}

.p-dialog-maximized .p-dialog-content {
  -webkit-transition: height 0.3s;
  transition: height 0.3s;
}

.p-component-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.quantity-input:focus {
  background: red;
}

.quantity-input__modifier,
.quantity-input__screen {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
}

.quantity-input__modifier {
  /* padding: 0.7rem; */
  width: 1.5rem;
  font-size: 1rem;
  background: #e8e8e8;
  color: #1e1e21 !important;
  border: 0 solid #dbdbdb;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.6);
  cursor: pointer;
  font-weight: bold;
}

.quantity-input__modifier:hover {
  background: #dadada;
  color: #555555;
}

.quantity-input__modifier--left {
  border-radius: 3px 0 0 3px;
}

.quantity-input__modifier--right {
  border-radius: 0 3px 3px 0;
}

.quantity-input__screen {
  width: 2rem;
  height: 1rem;
  padding: 0.7rem;
  font-size: 1rem;
  border: 0;
  border-top: 0 solid #dbdbdb;
  border-bottom: 0 solid #dbdbdb;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.7);
  margin: 0px 5px 0px 5px;
  border-radius: 5px;
}

.p-panel-title {
  font-weight: bold !important;
}

.quantity_cart_div {
  margin-top: 35px !important;
}

.quantity_heading {
  font-size: 16px !important;
  line-height: 0rem;
  margin-bottom: 0rem;
}

.quantity-input {
  padding: 15px 0px 15px 0px;
}

@media (max-width: 600px) {
  .quantity-input__modifier {
    width: 20% !important;
  }
  .quantity-input__screen {
    width: 20% !important;
  }
  .p-panel-title {
    font-size: 13px !important;
  }
}

.p-dialog-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}

.stock-dialog div:nth-of-type(2) > div {
  min-width: 300px;
}

@media only screen and (max-width: 600px) {
  .graph-mobile {
    display: none;
  }
}

.vertical-align-middle {
  vertical-align: middle;
}

.logo-loader {
  position: absolute !important;
}

@font-face {
  font-family: "icomoon";
  src: url("../assets/fonts/icomoon.eot?6m8tea");
  src: url("../assets/fonts/icomoon.eot?6m8tea#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/icomoon.ttf?6m8tea") format("truetype"),
    url("../assets/fonts/icomoon.woff?6m8tea") format("woff"),
    url("../assets/fonts/icomoon.svg?6m8tea#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-point-available:before {
  content: "\e90a";
}
.icon-point-pending:before {
  content: "\e90b";
}
.icon-point-redeemed:before {
  content: "\e90c";
}
.icon-bucks:before {
  content: "\e900";
}
.icon-Covetrus:before {
  content: "\e901";
}
.icon-last-login:before {
  content: "\e902";
}
.icon-last-order:before {
  content: "\e903";
}
.icon-order-history:before {
  content: "\e904";
}
.icon-purchases:before {
  content: "\e905";
}
.icon-rebates:before {
  content: "\e906";
}
.icon-saving:before {
  content: "\e907";
}
.icon-select-vendor:before {
  content: "\e908";
}
.icon-sign-up-date:before {
  content: "\e909";
}

.pagination-fixed {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  transition: 0.3s ease-in-out 0s;
}

.connect-button {
  background: #ffffff !important;
  border: 1px solid #ff5722 !important;
  width: 50%;
  color: #ff5722 !important;
  font-size: 8px !important;
  margin-left: 10px !important;
  margin-bottom: 5px !important;
  padding: 0px !important;
}
.connect-button:hover {
  background: #ff5722 !important;
  border: 1px solid #ff5722 !important;
  /* width: 100%; */
  color: #ffffff !important;
}
.cart-primary-btn {
  color: var(--primary);
}

.bk-order-btn label {
  top: -3px !important;
  font-family: Roboto, sans-serif !important;
  font-weight: 350;
  color: hsl(0, 0%, 50%);
}
#outlined-basic {
  line-height: 23px !important;
}
.bk-order-btn {
  padding-top: 20px !important;
}
.date-rng-1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.date-rng-1 label {
  top: -3px !important;
}
.date-rng-1 input {
  padding: 11.5px 14px !important;
}
.date-rng-1 .select-date-btn {
  padding: 10px !important;
}

.simi-saving {
  text-align: center;
  display: flex;
  align-items: center;
  padding: 5px;
  background: #f2f2f2;
  border-radius: 50px;
}
.simi-saving:hover {
  filter: drop-shadow(0px 0px 3px #c2c2c2);
}
.simi-saving p {
  font-size: 13px;
  /* Change this style to keep the style for long description. For example, we can put long description for alternative product */
  margin: 8px;
  padding: 0px;
  line-height: unset;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: calc(100% - 40px);
  font-weight: bold;
  color: var(--primary);
}
.simi-saving svg {
  width: 40px;
}
.alternative-html-description p {
  text-align: center;
}

.stats-info .css-1wa3eu0-placeholder {
  font-size: 13px;
}
table.stripped-table {
  margin: 10px 0;
}
table.stripped-table td {
  padding: 16px;
}
table.stripped-table td.group-title {
  padding: 20px 0;
  background-color: #f2f2f2;
  border: 1px solid #d2d2d2;
}
table.stripped-table tr:nth-child(odd) {
  background-color: #f2f2f2;
}
table.stripped-table tr:nth-child(even) {
  background-color: #fcfcfc;
}

.action-td {
  max-width: none !important;
}
.alternative-product {
  border: 2px solid var(--active) !important;
  border-top: 16px solid var(--active) !important;
  box-shadow: 0px 2px 6px 0px;
}
.unalternative-product .main-panel {
  margin-top: 16px;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-initial {
  cursor: initial;
}
.rdrDefinedRangesWrapper {
  display: none;
}

.custom-range-selector {
  margin-top: 60px;
}
.custom-range-selector .btn-pick-date {
  color: #23527c;
  background-color: #eee;
  margin: 4px;
  font-size: 12px;
  width: 120px;
}

.custom-range-selector .btn-pick-date:active {
  background-color: #23527c;
  color: white;
}

.custom-range-selector .btn-pick-date i {
  font-size: 16px;
  margin-right: 8px;
  padding-bottom: 2px;
}

.custom-range-selector .txt-date-input {
  font-size: 12px;
  padding: 0;
  width: 62px;
  margin: 4px 24px;
}

.custom-range-selector .txt-date-input input {
  padding: 8px;
}

.shipping-checkbox {
  margin: auto;
  font-size: 20px;
  color: #5cb85c !important;
}
.greenship-message {
  font-size: 14px;
  color: #5cb85c;
}

.top-z-index {
  pointer-events: auto;
  word-break: break-all;
}
