.title_header {
  text-transform: none;
  font-size: 17px;
  color: #595855;
  text-align: left;
}

.close_header {
  text-transform: none;
  font-size: 17px;
  color: #595855;
  float: right;
}

.confirm_cart_modal>div:nth-child(2)>div {
  min-width: 60% !important;
}

.parent_product_div {
  display: block !important;
}

.admin-sign-in-page-wrap::before {
  background-color: red;
}

.admin-title {
  color: #011154;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  margin-left: 10px;
}

.icon_check {
  width: 30px;
  color: #07aa07;
  font-weight: 700;
  line-height: 5px !important;
  font-size: 22px !important;
}

.check_icon_grid {
  float: left;
  margin-left: 5% !important;
}

.product_grid {
  float: left;
  margin-left: 5% !important;
}

.product_image_modal {
  /* max-width: 300px; */
  max-height: 100px;
  width: unset;
}

.product_image_div {
  width: 100%;
}

.desc_grid {
  margin-bottom: 20px;
}

.prod_desc {
  font-size: 18px;
  color: #0b0909;
  text-align: left;
  display: flex;
}

.prod_qty {
  margin-top: 25px;
}

.cart_title {
  font-size: 18px;
  color: rgb(7 170 7);
  font-weight: bold;
  display: flex;
  align-items: center;
  line-height: 20px;
}

.cart_title_grid {
  margin-top: 10px !important;
  margin-left: 40px !important;
}

.sub_total {
  font-size: 16px;
  font-weight: bold;
  margin: 5px;
}

.shopping_button {
  /* margin-top: 10px !important;
    text-align: right; */
  float: right;
  height: 100%;
  display: flex;
  align-items: center;
}

.sweet-alert-bottom {
  position: absolute;
  width: 100%;
  bottom: 5px;
  text-align: right;
  left: 0;
}

.confirm_box {
  padding: 15px;
  line-height: 5px;
  background: #f2f2f2;
  border-radius: 5px;
  width: 100%;
}

.page-header-container {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.page-header-container .title {
  color: #011154;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 35.2px;
  padding: 0px 20px 0px 0px;
  border-right: 2px solid rgba(23, 32, 71, 0.4);
}

.page-header-container .sub-title {
  color: #0600fe;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  padding-left: 20px;
}

.confirm_cart_item {
  background-color: #ffff;
  width: 50%;

  float: right;
  text-align: right;
  padding: 15px;
  line-height: 5px;
  /* height: 15px;
    border-style:  solid;
    border-width: thin;*/
  border-radius: 5px;
  font-size: 18px;
  border: 2px solid #c9c9c9;
}

.view_cart_btn {
  border-radius: 3px !important;
  border: 2px solid #0020cc !important;
  background: transparent;
  display: inline-block !important;
  color: #0020cc !important;
  cursor: pointer !important;
}

.main-parent {
  display: flex;
}

.body-parent {
  display: flex;
  /* align-items: baseline; */
  margin: 14px 0px;
}

.scrollbar-thin::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

.scrollbar-thin::-webkit-scrollbar-button {
  background: #ccc;
}

.scrollbar-thin::-webkit-scrollbar-track-piece {
  background: #888;
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background: #eee;
}

@media only screen and (max-width: 600px) {
  .body-parent {
    display: block !important;
    text-align: center;
    margin: 14px 0px !important;
  }

  .main-parent {
    display: block !important;
  }

  .confirm_cart_item {
    background-color: #ffff;
    width: 100% !important;
  }

  .confirm_box {
    width: 100% !important;
    margin: 10px 0px;
  }

  .prod_desc {
    text-align: center !important;
  }

  .view_cart_btn {
    width: 100% !important;
  }

  .cntinue-shp {
    width: 100% !important;
    margin: 10px 0px !important;
  }
}

@media screen and (min-width: 1900px) {
  .admin-sidebar-wrap {
    height: 100vh !important;
  }
}

.admin-sidebar-wrap {
  height: -webkit-fill-available;
}