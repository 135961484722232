@import "src/redesign/assets/scss/variables.scss";

.message-container {
  background: var(--active);
  text-align: center;
  padding: 8px 12px;

  div,
  p,
  a,
  span {
    margin: 0;
    text-decoration: none;
    font-size: 14px !important;
    font-weight: 700;
    color: $secondary;

    &:hover {
      text-decoration: none;
    }
  }
}
