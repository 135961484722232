.main-banner-section {
  display: grid; // Using grid layout
  grid-template-columns: 2fr 1fr; // Large banner occupies 2x the space of smaller banners
  grid-template-rows: 440px; // Fix the height to match the large banner
  gap: 32px; // Horizontal and vertical spacing

  .column-large {
    grid-column: 1; // Large banner spans the first column
    grid-row: 1; // Only occupies the first row
  }

  .column-small {
    display: grid; // Nested grid for the stacked small banners
    grid-template-rows: repeat(2, 1fr); // Divide the height equally
    gap: 16px; // Spacing between the small banners
    grid-column: 2; // Small banners in the second column
  }

  .rectangle {
    background-color: lightblue; // Placeholder background color
    border: 1px solid #ccc; // Light border
    border-radius: 4px; // Rounded corners
    padding: 1rem; // Inner spacing
    text-align: center; // Centered text
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr; // Single column layout for smaller screens
    grid-template-rows: auto; // Stacked layout
    gap: 16px;

    .column-large,
    .column-small {
      grid-column: auto; // Reset column placement
      grid-row: auto; // Stack vertically
    }

    .rectangle {
      height: auto; // Adjust height for mobile
    }
  }
}
