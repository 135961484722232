.information-section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;

  .rectangle {
    background-color: #e0e0e0;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 1rem;
    text-align: center;
    height: 120px;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 16px;
  }
}
