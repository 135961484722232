/*------------------------------------------------------------
  5.1.1 headers
-----------------------------------------------------------*/

//header one styling
.iron-header-v1{
  box-shadow: none !important;
  background-color:$primary;
  .iron-header-top{
    background-color: rgba(255,255,255,.05); 
  }
  .iron-header-bottom{
   min-height:64px;
  }
  .third-party-header-top{
    background-color: #ed3030;
  }
  .third-party-logo-container{
    margin: auto;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .third-party-header-bottom{
    background-color: white;
  }
  .iron-header-menu{
    li{
      a{
        color: $base;
        text-transform: uppercase;
      }
    }
  }
}

