.redesign-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .redesign-content {
    flex: 1;
    padding: 32px 24px;
    z-index: 1;
  }
}
