@media only screen and (max-width: 970px) {
    .MuiGrid-spacing-xs-32-62{
            margin: 0px !important;
    }
}

.main-search .react-autosuggest__suggestions-container{
    position: relative;
}

.main-search input::placeholder {
    overflow: visible; /* place holder is hidden in Chrome browser in the floating menu*/
}

.suggestionContainer{
    position: absolute;
    width: 82%;
    border: 3px solid #7b85ca;
    z-index: 1000;
    background-color: white;
    margin-left: 3%;
}

.search-form {
  form {
    position: relative;
  }
}

.main-search input {
    color: black !important;
}

