
.quantity-input__screen{
    width: 3rem !important;
  }
  .selected-checkbox{
    cursor: pointer;
    color: var(--active);

 
}
body .main-dialogue {
  width: 60vw !important;
  /* left: 60% !important; */
}
  .quantity-dropdown div:nth-child(2) > div:first-child{
    max-height: 180px;
  }


@media only screen and (min-width: 768px) and (max-width:1024px) {
  body .main-dialogue {
    width: 75vw !important;
    left: 60% !important;
  }
 
}

@media only screen and (max-width: 600px) {
  body .main-dialogue {
    width: 85vw !important;
  }
  body .main-dialogue .p-dialog-content h4 {
    font-size: 23px;
  }
  body .main-dialogue .p-dialog-content .select-dropdown{
    width: 45% !important;
  }
  /* .select-dropdown .css-4ljt47-MenuList{
    max-height: 180px;
  } */
} 

.disabled-checkbox{
  color: gray;
}
/* @media only screen and (max-width: 600px) {
  .cart .p-dialog{
    width: 85vw !important;
  }
} */