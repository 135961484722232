.file-process-container {
  position: relative;
  margin: auto;
  border-radius: 5px;
}

.custom-spinner {
  position: absolute;
  left: 48%;
  top: 10%;
  color: #1985ac;
}

.file-process-message {
  text-align: center;
  padding: 70px 0 50px 0;
  margin: 15px;
}
.file-process-message h6 {
  text-align: center;
  color: darkgray;
}
